import React from "react";
import { Container } from "semantic-ui-react";

import Text from "../components/Text";

export default (props) => (
  <Container align="center">
    <iframe
      title="GO Podcast Player"
      height="200px"
      width="100%"
      frameBorder="no"
      scrolling="no"
      seamless
      src={`https://player.simplecast.com/${props.episodeId}?dark=false`}
    />
    <Text size="large">
      Listen to the latest GO Podcast episode or subscribe using your favorite podcasting service.
    </Text>
  </Container>
);
